<script setup>
import axios from '@/helpers/axios';
import moment from "moment";
import { useI18n } from 'vue-i18n';
import AddKeyDrawer from '@/views/api-keys/AddKeyDrawer.vue'
import { toast } from 'vue3-toastify'
import 'vue3-toastify/dist/index.css'

definePage({
  meta: {
    layout: 'default',
    role: 'admin'
  },
})

const { t } = useI18n()

const showAddKeyDrawer = ref(false)
const searchQuery = ref('')
const rowPerPage = ref(10)
const currentPage = ref(1)
const totalPage = ref(1)
const totalItems = ref(0)
const items = ref([])

const getData = () => {
  axios.get('/admin/api-keys', {
    params: {
      q: searchQuery.value,
      perPage: rowPerPage.value,
      currentPage: currentPage.value,
    },
  }).then(response => {
    items.value = response.data.data
    totalPage.value = response.data.meta.last_page
    totalItems.value = response.data.meta.total
  }).catch(e => {
    console.error(e)
  })
}

watchEffect(() => {
  getData()
})

watchEffect(() => {
  if (currentPage.value > totalPage.value)
    currentPage.value = totalPage.value
})

const deleteKey = (deviceUuid, key) => {
  axios.post('/admin/api-keys/delete', {
    uuid: deviceUuid,
    key: key,
  }).then(response => {

    toast.success(t('api_keys.key_deleted'), {
      autoClose: 3000,
    })
    getData()

  }).catch(e => {
    toast.error(t('layout.error'), {
      autoClose: 3000,
    })
    console.error(e)
  })
}


// 👉 Computing pagination data
const paginationData = computed(() => {
  const firstIndex = items.value.length ? (currentPage.value - 1) * rowPerPage.value + 1 : 0
  const lastIndex = items.value.length + (currentPage.value - 1) * rowPerPage.value
  
  return t('tables.showing', { firstIndex, lastIndex, totalItems: totalItems.value })
})

const resolveBoolVariant = (data) => {
  if (data)
    return { color: 'success', text: t('layout.yes') }

  return { color: 'error', text: t('layout.no') }
}
</script>

<template>
  <div>
    <VCard
      v-if="items"
      id="items-list"
      :title="$t('api_keys.title') + ' 🔑'"
    >
      <VCardText class="d-flex align-center flex-wrap gap-4">
        <!-- 👉 Rows per page -->
        <div
          class="d-flex align-center"
          style="width: 150px;"
        >
          <span class="text-no-wrap me-3">{{ $t('tables.show') }}</span>
          <VSelect
            v-model="rowPerPage"
            density="compact"
            :items="[10, 20, 30, 50]"
          />
        </div>

        <VSpacer />

        <div class="d-flex align-center flex-wrap gap-4">
          <!-- 👉 Search  -->
          <div class="items-list-filter">
            <VTextField
              v-model="searchQuery"
              :placeholder="$t('api_keys.search_key')"
              density="compact"
            />
          </div>
          <VBtn
            prepend-icon="tabler-plus"
            @click="showAddKeyDrawer = true"
          >
            {{ $t('api_keys.add_key') }}
          </VBtn>
        </div>
      </VCardText>

      <VDivider />

      <!-- SECTION Table -->
      <VTable class="text-no-wrap items-list-table">
        <!-- 👉 Table head -->
        <thead class="text-uppercase">
          <tr>
            <th scope="col">
              {{ $t('api_keys.device') }}
            </th>

            <th scope="col">
              {{ $t('api_keys.key') }}
            </th>

            <th scope="col">
              {{ $t('api_keys.requests') }}
            </th>

            <th scope="col">
              {{ $t('api_keys.read') }}
            </th>

            <th scope="col">
              {{ $t('api_keys.write') }}
            </th>

            <th scope="col">
              {{ $t('api_keys.description') }}
            </th>

            <th scope="col">
              {{ $t('api_keys.updated') }}
            </th>

            <th scope="col" />
          </tr>
        </thead>

        <!-- 👉 Table Body -->
        <tbody>
          <tr
            v-for="(item, i) in items"
            :key="item.i"
            style="height: 3.75rem;"
          >
            <!-- 👉 Device -->
            <td>
              <RouterLink :to="{ name: 'devices-uuid', params: { uuid: item.device.uuid } }">
                {{ item.device.name }}
              </RouterLink>
            </td>

            <!-- 👉 Key -->
            <td>
              {{ item.key }}
            </td>

            <!-- 👉 Requests -->
            <td>
              {{ item.requests }}
            </td>

            <!-- 👉 Read -->
            <td>
              <VChip
                :color="resolveBoolVariant(item.read).color"
                size="small"
                class="font-weight-medium"
              >
                {{ resolveBoolVariant(item.read).text }}
              </VChip>
            </td>

            <!-- 👉 Write -->
            <td>
              <VChip
                :color="resolveBoolVariant(item.write).color"
                size="small"
                class="font-weight-medium"
              >
                {{ resolveBoolVariant(item.write).text }}
              </VChip>
            </td>

            <td>
              {{ item.description }}
            </td>

            <!-- 👉 Sampled -->
            <td>
              {{ moment.utc(item.updated_at).local().format("Y-MM-DD HH:mm") }}
            </td>

            <!-- 👉 Actions -->
          <td
            class="text-center"
            style="width: 5rem;"
          >
            <VBtn
              icon
              size="x-small"
              color="default"
              variant="text"
            >
              <VIcon
                size="22"
                icon="tabler-dots-vertical"
              />

              <VMenu activator="parent">
                <VList>
                  <VListItem
                    :title="$t('layout.delete')"
                    @click="deleteKey(item.device.uuid, item.key)"
                  />
                </VList>
              </VMenu>
            </VBtn>
          </td>
          </tr>
        </tbody>

        <!-- 👉 table footer  -->
        <tfoot v-show="!items.length">
          <tr>
            <td
              colspan="8"
              class="text-center text-body-1"
            >
              {{ $t('api_keys.no_keys') }}
            </td>
          </tr>
        </tfoot>
      </VTable>
      <!-- !SECTION -->

      <VDivider />

      <!-- SECTION Pagination -->
      <VCardText class="d-flex align-center flex-wrap gap-4 py-3">
        <!-- 👉 Pagination meta -->
        <span class="text-sm text-disabled">
          {{ paginationData }}
        </span>

        <VSpacer />

        <!-- 👉 Pagination -->
        <VPagination
          v-model="currentPage"
          size="small"
          :total-visible="5"
          :length="totalPage"
        />
      </VCardText>
      <!-- !SECTION -->
    </VCard>
    <AddKeyDrawer
      :is-drawer-open="showAddKeyDrawer"
      @update:is-drawer-open="(val) => showAddKeyDrawer = val"
      @submit="getData"
    />
  </div>
</template>

<style lang="scss">
#items-list {
  .items-list-actions {
    inline-size: 8rem;
  }

  .items-list-filter {
    inline-size: 12rem;
  }
}
</style>
